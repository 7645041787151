/*////////////////////////////////////////////////////////////////////////////////
// App.css
// Styling for App.css
////////////////////////////////////////////////////////////////////////////////*/

/*////////////////////////////////////////////////////////////////////////////////
// Fonts
////////////////////////////////////////////////////////////////////////////////*/

@font-face {
  src: url(./fonts/Montserrat-ExtraLight.ttf);
  font-family: Montserrat-ExtraLight;
}

@font-face {
  src: url(./fonts/Montserrat-Bold.ttf);
  font-family: Montserrat-Bold;
}

/*////////////////////////////////////////////////////////////////////////////////
// Css
////////////////////////////////////////////////////////////////////////////////*/

.Adjust {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.App {
  min-height: 100vh;
  flex-direction: column;
}

.Centre {
  width: 50vw;
}

.Hspace {
  height: 40px;
}

.Title {
  width: 230px;
}

.Row {
  flex-direction: row;
  justify-content: space-evenly;
}

.Row2 {
  flex-direction: row;
  justify-content: space-evenly;
}

.Column {
  flex-direction: column;
}

.Box {
  min-width: 160px !important;
}

.Margin {
  margin-top: 15px;
  margin-bottom: 30px;
}

.Margin3 {
  margin-top: 50px;
}

.Margin4 {
  margin-bottom: 15px;
}

.Margin5 {
  margin-bottom: 4vh;
}

.Header_title {
  font-size: calc(1vmin + 40px);
  width: 80vw;
  color: rgb(255, 255, 255);
  font-family: Montserrat-ExtraLight;
}

.Desc_title {
  font-size: calc(1vmin + 15px);
  width: 70vw;
}

.Text_box {
  height: 20vh;
  width: calc(30px + 40vw);
  min-height: 160px;
  margin-bottom: 20px;
  margin-top: 50px;
}

.Stat_box {
  height: 35vh;
  width: calc(35vw + 200px);
  min-height: 160px;
  justify-content: space-evenly;
  margin-bottom: 40px;
  margin-top: 60px;
}

.Big {
  height: 600px;
}

.Desc_para {
  font-size: calc(1vmin + 25px);
  width: 80vw;
  color: rgb(3, 3, 3);
  font-family: Montserrat-ExtraLight;
}

.Desc_micro {
  font-size: calc(1vmin + 10px);
  width: calc(200px + 25vw);
}

.Values_micro {
  font-size: calc(0.5vmin + 10px) !important;
  font-family: Montserrat-ExtraLight;
}

.Desc_micro_micro {
  font-size: calc(1vmin + 5px);
  width: calc(200px + 25vw);
}

/*////////////////////////////////////////////////////////////////////////////////
// Images
////////////////////////////////////////////////////////////////////////////////*/

.Background {
  height: 80vh;
  width: 100vw;
  background-image: url("./assets/cover.png");
}

.Stat1 {
  height: 25vmin;
  width: 25vmin;
  min-height: 140px;
  min-width: 140px;
}

/*////////////////////////////////////////////////////////////////////////////////
// Media
////////////////////////////////////////////////////////////////////////////////*/

@media only screen and (max-width: 800px) { 
  .Centre {
    width: 85vw;
  }
  .Background {
    height: 80vh;
    width: 100vw;
    margin-bottom: 80px;
    background-image: url("./assets/cover-phone.png");
  }
}

@media (prefers-reduced-motion: no-preference) {
  .Enter {animation: SlideIn 1.5s;}
}


@media only screen and (max-width: 1000px) { 
  .Hspace {
    height: 130px;
  }
  .Row {
    flex-direction: column;
    justify-content: space-evenly;
  }
}

@keyframes SlideIn {
  from {opacity: 0;}
  50% {opacity: 0; transform: translateY(20px)}
  to {transform: translateY(0px);}
}
